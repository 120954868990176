<template>
    <div class="table">
        <!-- 头部 -->
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <!-- 表格内容 -->
        <div class="container">
            <!-- <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="标题:">
                    <el-input v-model="queryParams.name" placeholder="请输入标题" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form> -->
            <div class="action">
                <el-button type="primary" @click="add()">新增</el-button>
            </div>
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable">
                <el-table-column prop="id" label="id" width="300" align="center"></el-table-column>
                <el-table-column prop="price" label="加价值" align="center" width="300"></el-table-column>
                <el-table-column label="加价类型" prop="ty" align="center" width="300">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.ty === 1">固定金额</el-tag>
                        <el-tag type="primary" v-if="scope.row.ty === 2">百分比</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="300">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 新增 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
            <el-form ref="form" :model="form" label-width="90px" :rules="rules" label-position="left">
                <el-form-item label="价格" prop="price" v-if="form.ty ===1">
                    <el-input v-model="form.price" placeholder="请输入价格"></el-input>
                </el-form-item>
                <el-form-item label="百分比" prop="price" v-else>
                    <el-input v-model="form.price" placeholder="请输入百分比"></el-input>
                </el-form-item>
                <el-form-item label="加价类型" prop="ty">
                    <el-radio-group v-model="form.ty">
                        <el-radio :label="1">固定金额</el-radio>
                        <el-radio :label="2">百分比</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import loadEvents from '@/utils/loading'
import Url from '@/components/Url';
import Picture from '@/components/Picture';
import { request } from '@/common/request';
import { deal } from '@/common/main';
let loads = new loadEvents();
export default {
    name: 'seller',
    components: {
        Breadcrumb,
        Pagination,
        Url,
        Picture
    },
    data() {
        return {
            header: {},
            menuList: ['营销', '加价设置'],
            queryParams: {
                page: 1,
                pagesize: 10
            },
            //验证规则
            rules: {
                price: [{
                    required: true,
                    message: '加价值不能为空',
                    trigger: 'blur'
                }, { message: '请输入数字值', pattern: '^[0-9%]' }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
                ty: [{
                    required: true,
                    message: '加价类型不能为空',
                    trigger: 'blur'
                }],
            },
            loading: false,
            pageInfo: {},
            addVisible: false,
            confirmVisible: false,
            confirmContent: '',
            title: '加载中',
            mode: 'add',
            form: {},
        }
    },
    created() {
        this.loadData();
    },

    //图片上传
    methods: {
        loadData() {
            this.loading = true;
            let _this = this
            request.get('/account/price/increase/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        //新增
        add() {
            this.mode = 'add';
            this.title = '新增';
            this.form = {
                state: 2,
                ty: 1
            };
            this.multiple = true
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        //编辑
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑';
            this.multiple = false
            //处理时间
            this.form = Object.assign({}, row);
            this.form.price = this.form.price + ''
            this.addVisible = true;
        },
        //状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.form.ctime = deal.timestamp(this.form.ctime);
            this.form.etime = deal.timestamp(this.form.etime);
            this.confirmVisible = true;
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = this.mode == 'add' ? '/account/price/increase/add' : '/account/price/increase/edit';
                        //处理时间
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/account/price/increase/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }
    }
};
</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

.span_gray {
    font-size: 12px;
    color: gray;
}

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 200px;
    line-height: 200px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 150px;
    height: 200px;
    line-height: 200px;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}

.dialog_box .el-button {
    margin-left: 20px;
}
</style>